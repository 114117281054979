import React from "react";

function index() {
  return (
    <div className="product-info-container">
      <h4>KUYUMCU DÖKÜM MALZEMELERİ</h4>
      <p>
        - Grafit pota ve pota gömleği <br />
        - Topcast döküm için yolluk ( Seramik ürün grubu)
        <br />
        - Grafit sprey
        <br />
        - BN sprey
        <br />
        - Pota çamuru <br />
        - Grafit conta <br />
        - Sızdırmazlık ekipmanları <br />
        - Yağlayıcı ürün grubu <br />- Termokulp
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    </div>
  );
}

export default index;
